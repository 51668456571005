import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button, ButtonToolbar, ButtonGroup, OverlayTrigger, Jumbotron, Modal, Tooltip } from 'react-bootstrap';
// import BackgroundSlideshow from 'react-background-slideshow'
import 'bootstrap/dist/css/bootstrap.min.css';
import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';
import './assets/add.css'
import pulkkis_sm from './assets/pulkkis_sm.png'
import contact from './assets/contact.jpg'

// const restAddr = 'http://127.0.0.1:5000'
const restAddr = 'https://rest.score-tool.com'
// import image1 from '.\\assets\\Pulkkis.jpg'
// import image2 from '.\\assets\\Pulkkis2020.jpg' 


function doCard(title, description, photo, cardWidth, cardHeight, keywords, year){
  let tags = ''
  if(typeof(keywords) !== "undefined"){
    keywords.map(key=>tags+='<span class="badge badge-pill bg-secondary badgefont">'+key+'</span>')
    }
  return '<div class="card text-light mb-3" style="width:'+cardWidth+'px; height:'+cardHeight+'px">'+
          '<p class="card-header text-center workTitle" style="color:#eed">'+title+'</p>'+
          '<p class="card-header text-center workTitle2" style="color:#eed">'+year+'</p>'+
          '<div class="card-footer small">'+tags+'</div>'+
          '<img class="card-img-top" src="'+photo+'" alt="Card image" style="max-height:'+(cardHeight-150)+'px;max-width:'+cardWidth+'px;">'+
          '<div class="card-body">'+
            '<p class="card-text text-center">'+description+'</p>'+
          '</div>'+
        '</div>'
//             '<a href="#" class="btn btn-primary"> Score and info </a>'+
}

function doWidth(duration){
  if (duration<50){
    return 100+duration
  } else {
    return 150+duration
  }
}

function App() {

  const [works, setWorks] = useState([])
  const [favorites, setFavorites] = useState([])
  const [modalShow, setModalShow] = useState(false);
  const [workInfo, setWorkInfo] = useState({});
  const [search, setSearch] = useState([])
  const [page, setPage] = useState('works')
  const [grouping, setGrouping] = useState('off')
  

  useEffect(() => {
    // console.log('effect')
    axios
      .get(restAddr+'/works')
      .then(response => {
        // console.log('promise fulfilled')
        const sortedWorks = response.data.works.sort(function (a, b) {
          return b.year-a.year;
        });
        setFavorites(response.data.favorites)
        setWorks(sortedWorks)
      })
  }, [])
  // console.log('render', works.length, 'works')
  // console.log(works)
  // ...

  function cardsFromWorks(listOfWorks){
    let allWorks = [];
    listOfWorks.map(work => allWorks.push(
        { // Add work as card
          itemId: work.id,
          //mediaUrl: 'https://i.picsum.photos/id/1003/1181/1772.jpg?hmac=oN9fHMXiqe9Zq2RM6XT-RVZkojgPnECWwyEF1RvvTZk',
          html: doCard(work.title, work.short_description, restAddr+'/'+work.id+'/thumb',doWidth(work.duration), doWidth(work.duration)+220, work.keywords, work.year),
          metaData: {
            type: 'text',
            height: doWidth(work.duration)+200,
            width: doWidth(work.duration),
            title: work.title,
            description: work.short_description
          }
        },
    ))
    return allWorks
      }
  
	// The options of the gallery (from the playground current state)
	const options = {
		galleryLayout: -1,
    // isVertical: true,
    collageDensity: '0.6',
    //hoveringBehaviour: 'DISAPPEARS',
    itemBorderWidth: 3,
    itemBorderRadius: 4,
    gallerySizeType: 'ratio',
    // gallerySizeRatio: 40,
    overlayBackground: 'rgba(6,6,6,0.75)',
	};

	// The size of the gallery container. The images will fit themselves in it
	const container = {
		width: window.innerWidth-200,
		height: window.innerHeight
	};


  function MyVerticallyCenteredModal(props) {
    if (typeof(workInfo.instrumentation)!=='undefined'){
    const imageSource=restAddr+"/"+workInfo.id+"/image"
    const audioSource=restAddr+"/"+workInfo.id+"/audio"
    const pdfSource=restAddr+"/"+workInfo.id+"/pdf"
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <h2>{workInfo.title}</h2>
          <p>
            {workInfo.instrumentation.reduce((accumulator, currentValue) => accumulator + ", " + currentValue)}
          </p>
          <p>duration approximately {workInfo.duration} minutes</p>
          <p>
            {workInfo.long_description}
          </p>
          <a href={pdfSource} target = "_blank" rel = "noopener noreferrer">
          <div className="d-grid gap-2">
          <Button variant="secondary" className="btn btn-secondary">
          Show score
        </Button>
        </div>
        </a>
        <h5>Listen an example</h5>
          <audio src={audioSource} controls />
          <div style={{width:"100%"}}>
          <img src={imageSource} style={{objectFit: "cover", width:"100%"}} alt="first page of the score"/>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
    } else return ''
  }

	// The eventsListener will notify you anytime something has happened in the gallery.
	const eventsListener = (eventName, eventData) => {
    // console.log({eventName, eventData})
    if(eventName==='ITEM_CLICKED'){
      const clickedWork = works.find(el => el.id === eventData.id);
      if(typeof(clickedWork)!=='undefined'){
        setWorkInfo(clickedWork)
        setModalShow(true)
      }
    }
  }; 

	// The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
	const scrollingElement = window;

//         <div style={{width:'100vw', height:'100vh', backgroundImage:`url(${image1})`}}>
// <div style={{height:'2vh'}}/>
//

// <div style={{opacity:0.4}}>
// <BackgroundSlideshow images={[image1, image2]}/>
// </div>

// <div style={{backgroundColor:'rgba(0,0,0,0.7)', margin:0}}>

function createGallery(){
  if (grouping==='off'){
    return (
      <ProGallery
      items={cardsFromWorks(filterWorks)}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    )
  }
  if (grouping==='year'){
    let yearGrouping = []
    for(let y=2022; y>1996;y--){
      yearGrouping.push(
        <div>
        <p className="display-3" style={{color: "#eed"}}>{y}:</p>
        <ProGallery
        items={cardsFromWorks(filterWorks.filter(wrk => wrk.year===y))}
        options={options}
        container={container}
        eventsListener={eventsListener}
        scrollingElement={scrollingElement}
      />
      </div>
      )
    }
    return(
      <div>
      {yearGrouping}
    </div>
    )
  }
  if (grouping==='genre'){
    let genreGrouping = []
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Opera:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => wrk.keywords.includes("opera")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Orchestra:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => (wrk.keywords.includes("orchestra") || wrk.instrumentation.includes("orchestra")) && !wrk.keywords.includes("opera") && !wrk.keywords.includes("concerto")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Concerto:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => wrk.keywords.includes("concerto") || wrk.keywords.includes("double concerto")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Chamber music:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => (wrk.keywords.includes("chamber") || wrk.instrumentation.includes("chamber")) && !wrk.instrumentation.includes("solo")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Vocal:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => wrk.keywords.includes("vocal") && !wrk.keywords.includes("opera")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    genreGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>Choir:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => wrk.keywords.includes("choir") && !wrk.keywords.includes("opera")))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
    </div>
    )
    return(
      
      <div>
      {genreGrouping}
    </div>
    )
  }
  if (grouping==='length'){
    let lengthGrouping = []
    lengthGrouping.push(
      <div>
      <h1 className="display-3" style={{color: "#eed"}}>60+ min.:</h1>
      <br/>
      <ProGallery
      items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=60))}
      options={options}
      container={container}
      eventsListener={eventsListener}
      scrollingElement={scrollingElement}
    />
  </div>)
      lengthGrouping.push(
        <div>
        <h1 className="display-3" style={{color: "#eed"}}>45+ min.:</h1>
        <br/>
        <ProGallery
        items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=45 && wrk.duration<60))}
        options={options}
        container={container}
        eventsListener={eventsListener}
        scrollingElement={scrollingElement}
      />
    </div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>30+ min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=30 && wrk.duration<45))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>20+ min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=20 && wrk.duration<30))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>15+ min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=15 && wrk.duration<20))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>10+ min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=10 && wrk.duration<15))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>under 5+ min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration>=5 && wrk.duration<10))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  lengthGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>5 min.:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => wrk.duration<5))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
</div>)
  return(
      
    <div>
    {lengthGrouping}
  </div>
  )
}
if (grouping==='own'){
  let favGrouping = []
  // console.log(favorites)
  favGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>My current favorites:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => favorites.includes(wrk.id)))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
  <br/>
  </div>
  )
  favGrouping.push(
    <div>
    <h1 className="display-3" style={{color: "#eed"}}>My other time favorites:</h1>
    <br/>
    <ProGallery
    items={cardsFromWorks(filterWorks.filter(wrk => !favorites.includes(wrk.id)))}
    options={options}
    container={container}
    eventsListener={eventsListener}
    scrollingElement={scrollingElement}
  />
  </div>
  )
  return(
      
    <div>
    {favGrouping}
  </div>
  )
}
}

function selectPage(selectedPage){
  //console.log(selectedPage)
  if(selectedPage==='works'){
    return(
      <div className='p-5 mx-5 rounded mobileLeftmarg' style={{backgroundColor:'black', width:"100vw"}}>
        <p className="display-6 text-sm-left" style={{color:'#eed'}}>List of works by Uljas Pulkkis</p>
        <p style={{color:'#eed'}}>group by</p>
        <ButtonToolbar className="mb-3 flex-wrap" aria-label="Toolbar with Button groups">
          <ButtonGroup className="me-2 flex-wrap" aria-label="First group">
          <Button variant="dark" style={{color:'gray'}} onClick={() => setGrouping('off')}>no grouping</Button>{' '}
            <Button variant="dark" style={{color:'gray'}} onClick={() => setGrouping('year')}>year</Button>{' '}
            <Button variant="dark" style={{color:'gray'}} onClick={() => setGrouping('genre')}>genre</Button>{' '}
            <Button variant="dark" style={{color:'gray'}} onClick={() => setGrouping('length')}>length</Button>{' '}
            <Button variant="dark" style={{color:'gray'}} onClick={() => setGrouping('own')}>my current favourites</Button>
          </ButtonGroup>
        </ButtonToolbar>
        <p style={{color:'#eed'}}>search</p>
        <OverlayTrigger overlay={<Tooltip id="tooltip-search" >
        <p style={{color:'gray'}}>If you want to search by the title, type "evolution", "tears of", etc...</p>
        <p style={{color:'gray'}}>If you want to search for chamber, orchestra or opera music, type "opera", "chamber", "orchestra" etc...</p>
        <p style={{color:'gray'}}>If you want to search by the composition year, type "2001", "2015", etc...</p>
        <p style={{color:'gray'}}>If you want to search by the instrumentation, type "piano", "accordion", "2222 2200 strings", etc...</p>
        <p style={{color:'gray'}}>If you want to search by the keywords, type "vocal", "concerto", "children", etc...</p>
        </Tooltip>}>
        <input type="text" placeholder="type here" onChange={(e) => {
          setSearch(e.target.value.split(' '));
          setGrouping('off');
        }
        }/>
        </OverlayTrigger>
        <p style={{color:'gray'}}>Now viewing {filterWorks.length} works</p>
      {createGallery()}
      </div>
    )
  }
  if(selectedPage==='bio'){
    return (<div className='p-5 mx-5 mr-15 rounded mobileLeftmarg' style={{backgroundColor:'black', width:"90vw"}}>
            <img src={pulkkis_sm} style={{'width': '20vw', 'display': 'inline', 'float': 'left', padding:10}} alt="painting of Uljas Pulkkis"/>
    <p style={{color:"#eed"}}>I am a classical composer, born in 1975, with work catalogue of over 50 works. Most of my works are composed for symphony orchestra with or without a soloist. I did my masters studies at Sibelius Academy, but studied also mathematics and computer science at Helsinki University. Even in my early orchestral works I tried to find algorithms to combine instrument sounds as a full-sounding spectrum. In 2018 I started my doctoral studies at Sibelius Academy in order to research possibilities of algorithmic help in orchestration practice. The motivation to start my research came from situations I encountered several times at the orchestra rehearsals: An inaudible solo instrument, i.e. the desired solo instrument, which I call target, is masked by the surrounding orchestration. The most popular orchestration study books, such as Piston and Adler, concentrate mainly on teaching how to combine instruments and how to orchestrate for certain effect, but very little about the audibility of the target. For example Samuel Adler has couple of mentions about the audibility, but does not provide a formula or a table about which combinations are safe and which not. There is definately time to have a comprehensive study on the orchestration audibility issue.</p>
    </div>)
  }
  if(selectedPage==='score'){
    return (<div className='p-5 mx-5 mr-15 rounded mobileLeftmarg' style={{backgroundColor:'black', width:"90vw"}}>
    <p style={{color:"#eed"}}>Score-Tool is an app which can be used for orchestration analysis and orchestration aid in composing work. Score-Tool is available as online-app, and in Apple app store for i-devices. The app is completely free and the source code is also available.</p> 
    <a href="https://score-tool.com" target = "_blank" rel = "noopener noreferrer">Open the online app in a new window</a>
    <div/>
    <a href="https://apps.apple.com/tt/app/score-tool-timbre-explorer/id1564238601" target = "_blank" rel = "noopener noreferrer">Open the Apple app store page in a new window</a>
    <div/>
    <a href="https://github.com/upulkkis/Score-Tool" target = "_blank" rel = "noopener noreferrer">Look the source code in a new window</a>
    </div>)
  }
  if(selectedPage==='research'){
    return (<div className='p-5 mx-5 mr-15 rounded mobileLeftmarg' style={{backgroundColor:'black', width:"90vw"}}>
    <p style={{color:"#eed"}}>I am currently doing academic orchestration research. My research include among others audibility, timbre and likeness concepts. These concepts can be explored with my Score-Tool app, and the theory is written down in my academic papers. </p> 
    <a href="https://research.score-tool.com" target = "_blank" rel = "noopener noreferrer">Open my academic research page</a>
    </div>
    )
    }
    if(selectedPage==='contact'){
      //      <div className="flip-box">
  //<div className="flip-box-inner">
  //<div className="flip-box-front">
  // <div className="flip-box">
  // <div className="flip-box-inner">
  // <div className="flip-box-front">
      return(
     <img src={contact} className="mx-5" style={{width:"200px"}} alt="uljas pulkkis contact information"/>
     )
    
    }
}

let filterWorks;
if (search.length) {
  const searchPattern = new RegExp(search.map(term => `(?=.*${term})`).join(''), 'i');
  filterWorks = works.filter(work => 
    work.title.match(searchPattern) || JSON.stringify(work.keywords).match(searchPattern) || work.year.toString().match(searchPattern) || JSON.stringify(work.instrumentation).match(searchPattern)
  );
} else {
  filterWorks = works;
}

  return (
    <div className="App" style={{backgroundColor:'black'}}>
      <header className="App-header">
        <div style={{height:'5vh'}}/>
        <Jumbotron className='mx-5 align-middle rounded hero-image' style={{backgroundColor:'#cfcfa0', color:'lightgray', height:'50vh'}}>
          <div className="rounded" style={{backgroundColor:'rgba(0,0,0,0.3)', width:'50vh'}}>
          <h1 className="display-2 text-lg-left titlefont">Uljas Pulkkis</h1>
          <p className="display-6 text-sm-left titlefont">contemporary composer</p>
          </div>
          <div style={{height:'1vh'}}/>
        </Jumbotron> 
        <p className="d-flex justify-content-start my-3">
        <div class="btn-group btn-group-lg mx-5 flex-wrap" role="group">
        <Button className="btn btn-dark btn-sm" style={{color:'gray'}} onClick={() => setPage('works')}>List of works</Button>
            <Button className="btn btn-dark btn-sm" style={{color:'gray'}} onClick={() => setPage('bio')}> Biography</Button>
            <Button className="btn btn-dark btn-sm" style={{color:'gray'}} onClick={() => setPage('score')}>Score-Tool</Button>
            <Button className="btn btn-dark btn-sm" style={{color:'gray'}} onClick={() => setPage('research')}>Research</Button>
            <Button className="btn btn-dark btn-sm" style={{color:'gray'}} onClick={() => setPage('contact')}>Contact</Button>
            </div>
          </p>
      </header>
      {selectPage(page)}
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}

export default App;
